.inner-references {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin-bottom: calc(var(--standard-margin) * 3);

  .card {
    gap: calc(var(--standard-margin) * 5);
  }
}

.references-title {
  color: rgb(92 63 72);
  font-weight: 750;
  margin-bottom: calc(var(--standard-margin) * 5)
}

/* top */
.references {
  display: flex;
  flex-direction: row;
  flex-grow: 1;
  gap: calc(var(--standard-margin) * 10);
  align-items: center;
}

.ref-row {
  display: flex;
  justify-content: center;
  width: 100%;
  img {
    max-width: 100%;
  }
}

/* bottom */
.please-read-row {
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: calc(var(--standard-margin) * 10);

  img {
    max-width: 75%;
  }
}

/* bottom left */
.please-read-col {
  display: flex;
  flex-direction: column;
}


/* bottom right */
.please-read {
  font-size: 24px;
  font-weight: bold;
  color: rgb(92 63 72);
  margin-bottom: calc(var(--standard-margin) * 3);
}