.App-link {
  color: #61dafb;
}

.App {
  color: rgb(92 63 72);
  display: flex;
  flex-direction: column;
  background-color: #FFEAEE;
}

/* footer */
.footer {
  display: flex;
  flex-direction: column;
  margin: 0 calc(var(--standard-margin) * 6);
}

.credits {
  display: flex;
  flex-direction: row;
  justify-content: space-evenly;
  color: #5C3F48;
  font-weight: bold;
  text-align: left;
  margin-top: calc(var(--standard-margin) * 2);
}

.mysti {
  text-decoration: none;
  color: #5C3F48;
}

.socials, .icons a {
  color: #5C3F48;
  padding-left: 0;
  font-size: 20px;
  display: flex;
  flex-direction: column;
  gap: var(--standard-margin);
}

.mysti:hover,.icons a:hover {
  color: #BA7087;
}

.icons {
  padding-left: 0;
  justify-content: flex-start;
}

.oni-peek {
  background: url('./components/pages/assets/dangoni_6_Month.png');
  background-size: cover;
  background-position: top;
  min-height: 120px;
  min-width: 120px;
  position: fixed;
  bottom: -30px;
  right: -25px;
  border-radius: 0.5rem;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
