.main-header {
	background-color: white;
	display: flex;
	padding: 0 2rem;
}

.container-fluid {
	justify-content: center;
}

.navbar-collapse {
	justify-content: flex-end;
}

.navbar-brand {
	font-family: 'Franklin Gothic Medium', 'Arial Narrow', Arial, sans-serif;
	font-size: 25px;
}

.header {
	margin-bottom: calc(var(--standard-margin) * 3)
}

.nav {
	align-items: center;
}

.collapse:not(.show) {
	.nav {
		gap: calc(var(--standard-margin) * 6)
	}
}

.button a {
	display: inline-block;
	overflow: hidden;
	white-space: nowrap;
	font-weight: 400;
	text-align: center !important;
	text-transform: uppercase;
	text-decoration: none;
	color: rgb(212, 53, 80);
	font-size: 24px;
	font-weight: bold;
}

.button a:hover {
	color: rgb(245 150 159);
}

.logo {
	width: 200px;
}