.inner-portfolio {
	width: 70rem;
}

.example-art-text {
	font-weight: 600;
	text-align: center;
	font-size: 1.3em;

	a {
		color: inherit;
		&:hover {
			color: #BA7087;
		}
	}
}

.gallery {
	display: flex;
	flex-direction: row;
	flex-wrap: wrap;
	justify-content: center;
}

ul {
	display: flex;
	flex-wrap: wrap;
	justify-content: center;
	gap: 0.5rem;
}