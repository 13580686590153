.card {
  background-color: #FFEAEE;
  border: none;
}

/* about container */
.about {
  display: flex;
  gap: calc(var(--standard-margin) * 5);
}

.about-col {
  display: flex;
  flex-direction: column;
  justify-content: center;

  img {
    width: 100%;
  }
}

/* left */
.pfp {
  border-radius: 50%;
  align-items: center;
}

/* right */
.about-me.title {
  font-size: 48px;
  color: rgb(255 210 136);
  font-weight: 750;
  margin-bottom: calc(var(--standard-margin) * 5);
}

.contents {
  font-weight: 600;
  margin-bottom: calc(var(--standard-margin) * 3);
}