.main-page {
	display: flex;
	flex-grow: 1;
}

.inner {
	display: flex;
	flex-direction: column;
	flex-grow: 1;
}

/* wallpaper */
.wallpaper {
	height: 640px;
}

.image {
	width: 100%;
	height: 100%;
	object-fit: cover;
}