.main {
	align-items: center;
	display: flex;
	flex-grow: 1;
	flex-shrink: 0;
	justify-content: center;
	max-width: 100%;
	position: relative;
	z-index: 1;
}

.inner-comm,
.inner-tos,
.inner-portfolio,
.inner-about,
.inner-references {
	--padding-horizontal: 1rem;
	--padding-vertical: 1.5rem;
	--spacing: 1.125rem;
	width: 55rem;
	position: relative;
	z-index: 1;
	padding: var(--padding-vertical) var(--padding-horizontal);
}

h3 {
	letter-spacing: 0.4rem;
	font-weight: bolder;
	font-size: 16pt;
	text-align: left;
}

span.closed {
	font-weight: lighter;
}

ul {
	display: flex;
	list-style-type: none;
}

.commImg {
	height: auto;
	max-height: 14rem;
	max-width: 100%;
}

.p {
	display: inline-block;
	text-align: left;
	font-size: 16pt !important;
	font-weight: normal;
	font-family: sans-serif;
}