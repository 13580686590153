.inner-tos {
	width: 40rem;
}

.tos {
	display: flex;
	flex-direction: column;
}

.image_yonmie {
	background-image: none;
	min-height: 150px;
	width: 150px;
	background-repeat: no-repeat;
	border-radius: 50%;
}

.rules {
	display: flex;
	flex-direction: column;
	text-align: left;
	font-size: 18pt;
	font-weight: normal;
	font-family: sans-serif;
	line-height: 36px;
}

.tos_lines {
	font-size: calc(var(--standard-margin) * 2);
}